var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{staticClass:"pa-10",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"lg12":"","md12":""}},[_c('p',{staticClass:"recover-text ml-6 mt-3"},[_vm._v("Cambiar Contraseña")])])],1),_c('v-layout',{staticClass:"mt-8",attrs:{"justify-center":""}},[_c('v-flex',{staticClass:"d-flex flex-column align-center mt-6 text-center",attrs:{"xs12":"","justify-center":""}},[_c('p',[_c('span',{staticClass:"large"},[_vm._v("Escribe una nueva contraseña ")])])])],1),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{staticClass:"mt-6",attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-text-field',{attrs:{"label":"Nueva contraseña","placeholder":"Ingrese la nueva contraseña","outlined":"","type":_vm.NewPassword.type,"autocomplete":"new-password","rules":[
            _vm.NewPassword.rules.mayus,
            _vm.NewPassword.rules.required,
            _vm.NewPassword.rules.digits,
          ],"append-icon":_vm.NewPassword.type == 'password' ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":function($event){_vm.NewPassword.type == 'password'
              ? (_vm.NewPassword.type = 'text')
              : (_vm.NewPassword.type = 'password')}},model:{value:(_vm.NewPassword.text),callback:function ($$v) {_vm.$set(_vm.NewPassword, "text", $$v)},expression:"NewPassword.text"}})],1)],1),_c('v-layout',{staticClass:"mt-2",attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-text-field',{attrs:{"label":"Confirmar nueva contraseña","placeholder":"Escriba nuevamente la contraseña","outlined":"","autocomplete":"new-password","type":_vm.ConfirmPassword.type,"rules":_vm.ConfirmPassword.rules,"append-icon":_vm.ConfirmPassword.type == 'password' ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":function($event){_vm.ConfirmPassword.type == 'password'
              ? (_vm.ConfirmPassword.type = 'text')
              : (_vm.ConfirmPassword.type = 'password')}},model:{value:(_vm.ConfirmPassword.text),callback:function ($$v) {_vm.$set(_vm.ConfirmPassword, "text", $$v)},expression:"ConfirmPassword.text"}})],1)],1)],1),_c('v-layout',{staticClass:"mt-2",attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-alert',{staticClass:"caption",attrs:{"type":"info","dense":"","outlined":""}},[_vm._v(" La contraseña debe ser igual o mayor a 8 digitos y tener al menos una letra mayúscula y una minúscula. ")])],1)],1),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{staticClass:"text-end",attrs:{"xs4":""}},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","text":"","disabled":_vm.loading},nativeOn:{"click":function($event){return _vm.back()}}},[_vm._v("Volver")]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","loading":_vm.loading},nativeOn:{"click":function($event){return _vm.UpdatePassword()}}},[_vm._v("Confirmar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }