<template>
  <div>
    <v-layout wrap class="pa-10">
      <!-- v-if="this.$store.getters.CreateNewPassword" -->
      <v-flex lg12 md12>
        <p class="recover-text ml-6 mt-3">Cambiar Contraseña</p>
      </v-flex>
    </v-layout>
    <v-layout class="mt-8" justify-center>
      <v-flex
        xs12
        class="d-flex flex-column align-center mt-6 text-center"
        justify-center
      >
        <p>
          <span class="large">Escribe una nueva contraseña </span>
        </p>
      </v-flex>
    </v-layout>
    <v-form ref="form" v-model="valid">
      <v-layout justify-center class="mt-6">
        <v-flex xs4>
          <v-text-field
            v-model="NewPassword.text"
            label="Nueva contraseña"
            placeholder="Ingrese la nueva contraseña"
            outlined
            :type="NewPassword.type"
            autocomplete="new-password"
            :rules="[
              NewPassword.rules.mayus,
              NewPassword.rules.required,
              NewPassword.rules.digits,
            ]"
            :append-icon="
              NewPassword.type == 'password' ? 'mdi-eye-off' : 'mdi-eye'
            "
            @click:append="
              NewPassword.type == 'password'
                ? (NewPassword.type = 'text')
                : (NewPassword.type = 'password')
            "
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout justify-center class="mt-2">
        <v-flex xs4>
          <v-text-field
            v-model="ConfirmPassword.text"
            label="Confirmar nueva contraseña"
            placeholder="Escriba nuevamente la contraseña"
            outlined
            autocomplete="new-password"
            :type="ConfirmPassword.type"
            :rules="ConfirmPassword.rules"
            :append-icon="
              ConfirmPassword.type == 'password' ? 'mdi-eye-off' : 'mdi-eye'
            "
            @click:append="
              ConfirmPassword.type == 'password'
                ? (ConfirmPassword.type = 'text')
                : (ConfirmPassword.type = 'password')
            "
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-form>
    <v-layout justify-center class="mt-2">
      <v-flex xs4>
        <v-alert class="caption" type="info" dense outlined>
          La contraseña debe ser igual o mayor a 8 digitos y tener al menos una
          letra mayúscula y una minúscula.
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout justify-center>
      <v-flex xs4 class="text-end">
        <v-btn
          class="mx-1"
          color="primary"
          text
          @click.native="back()"
          :disabled="loading"
          >Volver</v-btn
        >
        <v-btn
          class="mx-1"
          color="primary"
          @click.native="UpdatePassword()"
          :loading="loading"
          >Confirmar</v-btn
        >
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      valid: true,
      NewPassword: {
        placeholder: "Nueva contraseña",
        text: "",
        type: "password",
        rules: {
          mayus: (value) => {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
            return (
              pattern.test(value) ||
              "Debe contener al menos una mayúscula y una minúscula."
            );
          },
          required: (value) => {
            !!value || "Debe escribir una contraseña.";
          },
          digits: (value) => {
            (value.length >= 8 && value.length < 15) ||
              "Deben ser igual o mayor a 8 digitos";
          },
        },
      },

      ConfirmPassword: {
        placeholder: "Confirmar nueva contraseña",
        text: "",
        type: "password",
        rules: [
          (value) => !!value || "Este campo es requerido.",
          (value) =>
            value === this.NewPassword.text || "las contraseñas no coinciden",
        ],
      },
    };
  },
  methods: {
    back() {
      this.$router.push({ name: "Login" });
    },
    UpdatePassword() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.loading = true;
        var password = {
          clave_temporal: this.$store.getters.clave_temporal,
          newpassword: this.NewPassword.text,
          token: this.$store.getters.token_temporal,
        };
        let message = "",
          type = "";
        this.$store
          .dispatch("updateClave", password)
          .then((responseMessage) => {
            message = responseMessage;
            type = "success";
            this.$router.push({ name: "Login" });
          })
          .catch((responseMessage) => {
            message = responseMessage;
            type = "error";
          })
          .finally(() => {
            this.loading = false;
            this.$store.commit("setSnackbar", {
              active: true,
              text: message,
              top: true,
              right: true,
              color: type,
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.recover-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #050c42;
}
.large {
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #0077c8;
}
.small {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
}
.text-field {
  height: 44px;
  width: 570px;
  border-radius: 5px;
}
.alert {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #050c42 !important;
}

.alertStyle {
  border-radius: 10px;
  align-content: center;
}
:deep() .v-alert__icon {
  margin-right: 0px;
  color: #050c42;
  margin-top: 3px;
}
</style>
